<template>
	<div class="total drag" v-draw id="CfbParameter2">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_参数02_1</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				主汽温度控制模型（两级减温）
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context flex">
			<div class="one">
				<div class="shadow shadow1">
					<div class="flex">
						<div class="column1">B05-SP</div>
						<div class="column2">0.0</div>
					</div>
					<div class="flex">
						<div class="column1">B05-PV</div>
						<div class="column3">0.0</div>
					</div>
				</div>
				<div class="shadow1-words">水煤比前馈</div>
			</div>
			<div class="two">
				<div class="bg1">
					<div class=" flex">
						<div class="buttonr float1">R</div>
						<div class="button float2">
							<div class="button_word">切除</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonr float3" :style="{
								background:infoList.MCSRPQ__p__QK01 && !infoList.MCSRPQ__p__QK01.TS
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCSRPQ__p__QK01
								    ? toDetail(2,'QK01','MCSRPQ__p__QK01','','电负荷前馈'): ''">
							K
						</div>
						<div class="button float4">
							<div class="button_word" @click="toIpt(infoList.MCSYCL.SEL22,'按钮','SEL22','MCSYCL')">
								{{infoList.MCSYCL.SEL22 ? "投用" : "切除"}}
							</div>
						</div>
					</div>
					<div class="buttonx float5">A</div>
				</div>

			</div>
			<div class="three">
				<div class="shadow shadow2 float1">
					<div class="flex">
						<div class="column1">B01-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP11,'B01-SP','ZQTSP11','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP11}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B01-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV1','MCSYCL','QWSJPV1VV1')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV1,'QWSJPV1','MCSYCL','QWSJPV1VV1')">
							{{infoList.MCSYCL.QWSJPV1}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B01-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC1,'B01-TC','QWTC1','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC1}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float2">
					<div class="flex">
						<div class="column1">B02-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP12,'B02-SP','ZQTSP12','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP12}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B02-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV2','MCSYCL','QWSJPV2VV1')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV2,'QWSJPV2','MCSYCL','QWSJPV2VV1')">
							{{infoList.MCSYCL.QWSJPV2}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B02-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC2,'B02-TC','QWTC2','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC2}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float3">
					<div class="flex">
						<div class="column1">B03-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP_B1,'B03-TC','ZQTSP_B1','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP_B1}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B03-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV3','MCSYCL','QWSJPV3VV1')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV3,'QWSJPV3','MCSYCL','QWSJPV3VV1')">
							{{infoList.MCSYCL.QWSJPV3}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B03-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC3,'B03-TC','QWTC3','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC3}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float4">
					<div class="flex">
						<div class="column1">B04-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP_B1,'B04-SP','ZQTSP_B1','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP_B1}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B04-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV4','MCSYCL','QWSJPV4VV1')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV4,'QWSJPV4','MCSYCL','QWSJPV4VV1')">
							{{infoList.MCSYCL.QWSJPV4}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">B04-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC4,'B04-TC','QWTC4','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC4}}
						</div>
					</div>
				</div>
			</div>
			<div class="four">
				<div class="bg2">
					<div>
						<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK0I && !infoList.MCSXK__p__XK0I.SP
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0I
							? toDetail(1, 'XK0I', 'MCSXK__p__XK0I', '', '一级左主汽温度调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__QK0C8 && !infoList.MCSRPQ__p__QK08.TS
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCSRPQ__p__QK08
								    ? toDetail(2,'QK08','MCSRPQ__p__QK08','','QK08设定值'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK08SEL,'按钮','QK08SEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK08SEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
					<div class="float4">
						<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK0J && !infoList.MCSXK__p__XK0J.SP
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0J
							? toDetail(1, 'XK0J', 'MCSXK__p__XK0J', '', '一级右主汽温度调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__QK09 && !infoList.MCSRPQ__p__QK09.TS
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCSRPQ__p__QK09
								    ? toDetail(2,'QK09','MCSRPQ__p__QK09','','QK09设定值'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK09SEL,'按钮','QK09SEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK09SEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bg2 float5">
					<div>
						<div class="buttonx float1" :style="{
						        background:infoList.MCSXK__p__XK07 && !infoList.MCSXK__p__XK07.SP
						        ? '#2AFC30'
						        : 'red',
						       }" @click="infoList.MCSXK__p__XK07
								? toDetail(1, 'XK07', 'MCSXK__p__XK07', '', '二级左主汽温度调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
						        background:infoList.MCSRPQ__p__QK05 && !infoList.MCSRPQ__p__QK05.TS
						        ? '#2AFC30'
						        : 'red',
						        }" @click="infoList.MCSRPQ__p__QK05
						            ? toDetail(2,'QK05','MCSRPQ__p__QK05','','QK05设定值'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK02SEL,'按钮','QK02SEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK02SEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
					<div class="float4">
						<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK08 && !infoList.MCSXK__p__XK08.SP
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK08
							? toDetail(1, 'XK08', 'MCSXK__p__XK08', '', '二级右主汽温度调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__QK03 && !infoList.MCSRPQ__p__QK03.TS
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCSRPQ__p__QK03
								    ? toDetail(2,'QK03','MCSRPQ__p__QK03','','QK03设定值'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK03SEL,'按钮','QK03SEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK03SEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="five">
				<div class="shadow shadow3 float1 flex">
					<div class="column1">B01-A01</div>
					<div class="column3" @click="toCompon(2,'ZQAO1_B','MCSAO','ZQAO1_BVV1','1#减温水阀位输出')"
						@dblclick="Cclick(infoList.MCSAO.ZQAO1_B,'ZQAO1_B','MCSAO','ZQAO1_BVV1')">
						{{infoList.MCSAO.ZQAO1_B}}
					</div>
				</div>
				<div class="shadow shadow3 float2 flex">
					<div class="column1">B02-A02</div>
					<div class="column3" @click="toCompon(2,'ZQAO2_B','MCSAO','ZQAO2_BVV1','2#减温水阀位输出')"
						@dblclick="Cclick(infoList.MCSAO.ZQAO2_B,'ZQAO2_B','MCSAO','ZQAO2_BVV1')">
						{{infoList.MCSAO.ZQAO2_B}}
					</div>
				</div>
				<div class="shadow shadow3 float3 flex">
					<div class="column1">B03-A03</div>
					<div class="column3" @click="toCompon(2,'ZQAO3_B','MCSAO','ZQAO3_BVV1','3#减温水阀位输出')"
						@dblclick="Cclick(infoList.MCSAO.ZQAO3_B,'ZQAO3_B','MCSAO','ZQAO3_BVV1')">
						{{infoList.MCSAO.ZQAO3_B}}
					</div>
				</div>
				<div class="shadow shadow3 float4 flex">
					<div class="column1">B04-A04</div>
					<div class="column3" @click="toCompon(2,'ZQAO4_B','MCSAO','ZQAO4_BVV1','4#减温水阀位输出')"
						@dblclick="Cclick(infoList.MCSAO.ZQAO4_B,'ZQAO4_B','MCSAO','ZQAO4_BVV1')">
						{{infoList.MCSAO.ZQAO4_B}}
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList">
		</Manual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Parameter02', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1)
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbParameter2 {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 1.5vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 15vw;
			margin-top: 1.8vh;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.context {
			width: 39vw;
			height: 39vh;
			margin-top: 7vh;
			margin-left: 7vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.shadow {
				width: 7vw;
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 1px;
				border: solid 1px #236f8d;
				font-size: 1.2vh;
				line-height: 2.8vh;

				.column1 {
					width: 4vw;
					color: #8aeaff;
					margin-left: 0.3vw;
				}

				.column2 {
					width: 2vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 2vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.buttonx {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #00e4ff;
				color: white;
			}

			.buttonr {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #04ff57;
				color: white;
			}

			.button {
				width: 3vw;
				height: 2vh;
				background-color: #22fff7;
				border-radius: 0vh;
				font-family: PingFang-SC-Regular;
				font-size: 1vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;

				.button_word {
					width: 3vw;
					text-align: center;
					color: #0a4c62;
				}
			}

			.one {
				.shadow1 {
					height: 6vh;
					margin-top: 0vh;
					margin-left: 0vw;
				}

				.shadow1-words {
					color: #00e4ff;
					margin-top: 4.2vh;
					margin-left: 2.8vw;
				}
			}

			.two {
				.bg1 {
					width: 9vw;
					height: 15vh;
					background-image: url("~@/assets/images/CfbBoiler/para021(1).png");
					background-size: 100% 100%;
					margin-top: 1vh;

					.float1 {
						margin-left: 1vw;
						margin-top: -0.8vh;
					}

					.float2 {
						margin-left: 0.3vw;
						margin-top: 1.5vh;
					}

					.float3 {
						margin-left: 1vw;
						margin-top: 6vh;
					}

					.float4 {
						margin-left: 0.3vw;
						margin-top: 6.2vh;
					}

					.float5 {
						margin-left: 6.5vw;
						margin-top: 16vh;
					}
				}

			}

			.three {
				.shadow2 {
					height: 8vh;
					margin-top: 0vh;
					margin-left: 0vw;
				}

				.float2 {
					margin-top: 0.6vh;
				}

				.float3 {
					margin-top: 4.5vh;
				}

				.float4 {
					margin-top: 0.6vh;
				}
			}

			.four {
				.bg2 {
					width: 9vw;
					height: 15vh;
					background-image: url("~@/assets/images/CfbBoiler/para021(2).png");
					background-size: 100% 100%;
					padding: 0.1vw;

					.float1 {
						margin-top: -0.5vh;
						margin-left: 1.2vw;
					}

					.float2 {
						margin-top: 2vh;
						margin-left: 1.2vw;
					}

					.float3 {
						margin-top: 0vh;
						margin-left: 0.2vw;
					}

					.float4 {
						margin-top: 3vh;
					}
				}

				.float5 {
					margin-top: 5.8vh;
				}
			}

			.five {
				.shadow3 {
					height: 3vh;
				}

				.float1 {
					margin-top: 0.5vh;
					margin-left: 0vw;
				}

				.float2 {
					margin-top: 5vh;
					margin-left: 0vw;
				}

				.float3 {
					margin-top: 9.5vh;
					margin-left: 0vw;
				}

				.float4 {
					margin-top: 5.5vh;
					margin-left: 0vw;
				}
			}
		}
	}
</style>
